// @flow

import React from 'react'
import type { Node } from 'react'

import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import routes from '../routes'

import Layout from '../components/layout'
import { Heading1, BodyText } from '../components'

function Success(): Node {
  return (
    <Layout>
      <div className="bg-purple text-white h-full">
        <div className="py-12 md:py-32 px-8">
          <div className="w-full md:max-w-md md:mx-auto">
            <Helmet>
              <title>Cottages of Paradise Point &mdash; Received Message</title>
              <meta name="description" content="Success Page" />
            </Helmet>
            <section className="pb-5">
              <header className="major">
                <Heading1 className="mb-4" isTitle>
                  We’ve received your submission.
                </Heading1>
              </header>
              <BodyText className="text-purple-lightest mb-3">
                Please keep in mind that we are not longer accepting
                reservations due to the Hurricane which destroyed our property.
              </BodyText>
              <BodyText className="text-purple-lightest">
                <Link className="text-purple-lightest" to={routes.homepage}>
                  Return Home
                </Link>
              </BodyText>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Success
